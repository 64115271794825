import { Box, Zoom, Button } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import './loading.scss'
import Pending from '../../assets/images/loading.png'

export default function Loading() {
    const isSmallScreen = useMediaQuery("(max-width: 1020px)");

    return (
        <div className='loading-view'>
            <div className='dialogBg'>
            <div className='tipDialog font-24'>
              <div className="pending">pending</div>
              <Box display='flex' flexDirection='column' alignItems='center'>
                  <img src={Pending} style={{marginTop: 48,height: 150}} />
                  <div className="font-Montserrat-VariableFont mt-24">Please Wait...</div>
              </Box>
            </div>
          </div>
        </div>
    )
}