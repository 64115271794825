import { useDispatch, useSelector } from "react-redux";
import { Box, Button } from "@material-ui/core";
import { close, handle_obsolete } from "../../slices/MessagesSlice";
import store from "../../store";

import Success from '../../assets/images/success.svg'
import Error from '../../assets/images/error.svg'
import "./ConsoleInterceptor.js";
import './messages.scss'

// A component that displays error messages
function Messages() {
  const messages = useSelector(state => state.messages);
  const dispatch = useDispatch();
  // Returns a function that can closes a message
  const handleClose = function (message) {
    return () => {
      dispatch(close(message));
    };
  };
  return (
    <div>
      {messages.items.map((message, index) => {
        return (
          <div className="" key={index}>
            {
              message.severity == 'success' || message.severity == 'error' ?
                <div className='loading-view' style={{ display: message.open ? 'block' : 'none' }}>
                  <div className='dialogBg'>
                    <div className='tipDialog2 font-20'>
                      <Box display='flex' flexDirection='column' alignItems='center'>
                        <img src={message.severity == 'success' ? Success : Error} style={{ height: 100 }} />
                        <div className="text-center font-Montserrat-VariableFont mt-30">{message.text}</div>
                        <div className="goBtnBox mt-40">
                          <Button variant="contained" className='goBtn'
                            onClick={handleClose(message)}>
                            Got it
                          </Button>
                        </div>
                      </Box>
                    </div>
                  </div>
                </div>
                :
                <Snackbar open={message.open} key={index} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                  <Alert className="font-Montserrat-VariableFont" variant="filled" icon={false} severity={message.severity} onClose={handleClose(message)}>
                    <AlertTitle className="font-Montserrat-VariableFont">{message.title}</AlertTitle>
                    {message.text}
                  </Alert>
                </Snackbar>
            }
          </div>
        );
      })}
    </div>
  );
}
// Invoke repetedly obsolete messages deletion (should be in slice file but I cannot find a way to access the store from there)
// window.setInterval(() => {
//   store.dispatch(handle_obsolete());
// }, 3000);
export default Messages;
