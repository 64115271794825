import { ethers } from "ethers";
import { addresses } from "../constants";
import { abi as ierc20Abi } from "../abi/IERC20.json";
import { abi as richCityABI } from "../abi/RICHCity.json"
import { setAll } from "../helpers";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IBaseAddressAsyncThunk } from "./interfaces";
import { abi as GangABI } from "../abi/Gang.json";
import { abi as GangStakingABI } from "../abi/GangStaking.json"
import { abi as TokenStakingABI } from "../abi/TokenStaking.json"
import { reqGraphNFT } from "src/helpers/reqGraph";


export const getBalances = createAsyncThunk(
    "account/getBalances",
    async ({ address, networkID, provider }: IBaseAddressAsyncThunk) => {
        const CASHContract = new ethers.Contract(addresses[networkID].CASH as string, ierc20Abi, provider)
        const CASHDecimals = await CASHContract.decimals()
        const balances_cash = await CASHContract.balanceOf(address)

        const DRUGContract = new ethers.Contract(addresses[networkID].DRUG as string, ierc20Abi, provider)
        const DRUGDecimals = await DRUGContract.decimals()
        const balances_drug = await DRUGContract.balanceOf(address)

        const USDCContract = new ethers.Contract(addresses[networkID].USDC as string, ierc20Abi, provider)
        const USDCDecimals = await USDCContract.decimals()
        const balances_USDC = await USDCContract.balanceOf(address)
        const balances_pool = await USDCContract.balanceOf(addresses[networkID].feeAddress)
        return {
            balances_cash: ethers.utils.formatUnits(balances_cash, CASHDecimals),
            balances_drug: ethers.utils.formatUnits(balances_drug, DRUGDecimals),
            balances_USDC: ethers.utils.formatUnits(balances_USDC, USDCDecimals),
            balances_pool: ethers.utils.formatUnits(balances_pool, USDCDecimals),
        };
    },
);

const sleep = (time: number) => {
    return new Promise(resolve => setTimeout(resolve, time));
}



export const loadAccountDetails = createAsyncThunk(
    "account/loadAccountDetails",
    async ({ networkID, provider, address, blockNumber }: IBaseAddressAsyncThunk) => {
        const signer = provider.getSigner();

        let data = await reqGraphNFT(address)
        const loadData = async () => {
            if (blockNumber) {
                const reqNumber = data?._meta?.block?.number
                if (reqNumber > blockNumber + 8) {
                    return
                } else {
                    data = await reqGraphNFT(address)
                    await sleep(2000)
                    await loadData()
                }
            }
        }
        await loadData()
        const allNFT = data?.userNFT?.nftData || null
        let walletNFT, stakingNFT

        if (allNFT) {
            walletNFT = allNFT.filter((item: any) => {
                return item?.staking1 == false && item?.staking0 == false
            })
            walletNFT = walletNFT.sort((a: any, b: any) => {
                const metaDataa = JSON.parse(atob(a?.metaData.toString().slice(29)))
                const metaDatab = JSON.parse(atob(b?.metaData.toString().slice(29)))
                const blevel = metaDatab?.attributes[metaDatab?.attributes?.length - 4]?.value
                const alevel = metaDataa?.attributes[metaDataa?.attributes?.length - 4]?.value
                if (Number(blevel) != Number(alevel)) {
                    return Number(blevel) - Number(alevel)
                }
                return Number(a?.id) - Number(b?.id)
            })
            stakingNFT = allNFT.filter((item: any) => {
                return item?.staking1 == true && item?.staking0 == false
            })
            stakingNFT = stakingNFT.sort((a: any, b: any) => {
                const metaDataa = JSON.parse(atob(a?.metaData.toString().slice(29)))
                const metaDatab = JSON.parse(atob(b?.metaData.toString().slice(29)))
                const blevel = metaDatab?.attributes[metaDatab?.attributes?.length - 4]?.value
                const alevel = metaDataa?.attributes[metaDataa?.attributes?.length - 4]?.value
                if (Number(blevel) != Number(alevel)) {
                    return Number(blevel) - Number(alevel)
                }
                return Number(a?.id) - Number(b?.id)
            })
        }

        const GangStakingContract = new ethers.Contract(addresses[networkID].GangStaking, GangStakingABI, signer)
        const userGangID = Number(await GangStakingContract.userGang(address))
        let userNFTReward = 0, userCASHReward = 0, userDRUGReward = 0, userSCASHAmount = 0, userSDRUGAmount = 0, userNFTEstimatedReward = 0, userCASHEstimatedReward = 0, userDRUGEstimatedReward = 0, gangScoreUser = 0;

        if (userGangID != 0) {
            gangScoreUser = await GangStakingContract.getGangScore(userGangID)
            const nftStakingContract = new ethers.Contract(addresses[networkID].nftStaking, TokenStakingABI, signer)
            const drugStakingContract = new ethers.Contract(addresses[networkID].drugStaking, TokenStakingABI, signer)
            const cashStakingContract = new ethers.Contract(addresses[networkID].cashStaking, TokenStakingABI, signer)

            try {
                userNFTReward = await nftStakingContract.pending(address)
                userCASHReward = await cashStakingContract.pending(address)
                userDRUGReward = await drugStakingContract.pending(address)
                userNFTEstimatedReward = await nftStakingContract.getUserAmount(address) / await nftStakingContract.getTotalAmount(userGangID)
                userCASHEstimatedReward = await cashStakingContract.getUserScore(address) / await cashStakingContract.getTotalAmount(userGangID)
                userDRUGEstimatedReward = await drugStakingContract.getUserScore(address) / await drugStakingContract.getTotalAmount(userGangID)
            } catch (error) {
                // console.log(error)
            }

            userSCASHAmount = await cashStakingContract.getUserAmount(address)
            userSDRUGAmount = await drugStakingContract.getUserAmount(address)
        }
        return {
            userGangID,
            walletNFT,
            stakingNFT,
            userNFTReward: ethers.utils.formatEther(userNFTReward),
            userCASHReward: ethers.utils.formatEther(userCASHReward),
            userDRUGReward: ethers.utils.formatEther(userDRUGReward),
            userNFTEstimatedReward,
            userCASHEstimatedReward,
            userDRUGEstimatedReward,
            userSCASHAmount: ethers.utils.formatEther(userSCASHAmount),
            userSDRUGAmount: ethers.utils.formatEther(userSDRUGAmount),
            gangScoreUser
        };
    },
);


export const loadLeaderDetails = createAsyncThunk(
    "account/loadLeaderDetails",
    async ({ networkID, provider, address }: IBaseAddressAsyncThunk) => {
        const signer = provider.getSigner();
        const GangContract = new ethers.Contract(addresses[networkID].Gang, GangABI, signer)
        const GangStakingContract = new ethers.Contract(addresses[networkID].GangStaking, GangStakingABI, signer)
        let isStakedGang = false;
        let leaderID = Number(await GangStakingContract.leaderId(address))
        const balanceOfGang = Number(await GangContract.balanceOf(address))
        if (balanceOfGang > 0 || leaderID > 0) {
            isStakedGang = leaderID != 0
            leaderID = leaderID || Number(await GangContract.tokenOfOwnerByIndex(address, 0))
        }
        let leaderReward = 0, leaderPastReward = 0, gangScore = 0, gangPack = null
        if (isStakedGang) {
            leaderReward = await GangStakingContract.leaderReward(address);
            leaderPastReward = await GangStakingContract.leaderPastReward(address);
            gangScore = await GangStakingContract.getGangScore(leaderID)
            gangPack = await GangStakingContract.packs(leaderID);
        }
        return {
            leaderID,
            isStakedGang,
            leaderReward: ethers.utils.formatEther(leaderReward),
            leaderPastReward: ethers.utils.formatEther(leaderPastReward),
            gangScore,
            gangPack,
        };
    },
);

interface IAccountSlice {
    loading: boolean;
}


const initialState: IAccountSlice = {
    loading: false,
};

const accountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        fetchAccountSuccess(state, action) {
            setAll(state, action.payload);
        },
    },
    extraReducers: builder => {
        builder
            .addCase(loadAccountDetails.pending, state => {
                state.loading = true;
            })
            .addCase(loadAccountDetails.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(loadAccountDetails.rejected, (state, { error }) => {
                state.loading = false;
            })
            .addCase(getBalances.pending, state => {
                state.loading = true;
            })
            .addCase(getBalances.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(getBalances.rejected, (state, { error }) => {
                state.loading = false;
            })
            .addCase(loadLeaderDetails.pending, state => {
                state.loading = true;
            })
            .addCase(loadLeaderDetails.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(loadLeaderDetails.rejected, (state, { error }) => {
                state.loading = false;
            })
    },
});

const baseInfo = (state: RootState) => state.account;
export default accountSlice.reducer;
export const { fetchAccountSuccess } = accountSlice.actions;
export const getAccountState = createSelector(baseInfo, account => account);
