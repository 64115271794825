import { BigNumber, ethers } from "ethers";
import { addresses } from "../constants";
import { setAll, } from "../helpers";
import { createSlice, createSelector, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IBaseAsyncThunk } from "./interfaces";
import { abi as richCityABI } from "../abi/RICHCity.json"
import { abi as PairABI } from "../abi/Pair.json";
import { abi as ierc20Abi } from "../abi/IERC20.json";
import { abi as GangStakingABI } from "../abi/GangStaking.json"
import { abi as GangABI } from "../abi/Gang.json";
import { reqGraphDashBord, reqGraphHistory } from "src/helpers/reqGraph";
const initialState = {
    loading: false,
    loadingMarketPrice: false,
};
// export const getMarketPrice = async ({ networkID, provider }: IBaseAsyncThunk) => {
//     try {
//         const pairContract = new ethers.Contract(addresses[networkID].RICH_USDC, PairABI, provider);
//         const reserves = await pairContract.getReserves();
//         let marketPrice;
//         const token0 = await pairContract.token0();
//         if (token0.toString().toLowerCase() == addresses[networkID].USDC.toLowerCase()) {
//             marketPrice = reserves[0] * Math.pow(10, 3) / reserves[1];
//         } else {
//             marketPrice = reserves[1] * Math.pow(10, 3) / reserves[0];
//         }
//         return marketPrice;
//     } catch (error) {
//         return 0;
//     }

// }

export const loadAppDetails = createAsyncThunk(
    "app/loadAppDetails",
    async ({ networkID, provider }: IBaseAsyncThunk, { dispatch }) => {
        // const marketPrice = await getMarketPrice({ networkID, provider });//TODO: DRUGPrice
        const gStakingContract = new ethers.Contract(addresses[networkID].GangStaking, GangStakingABI, provider)
        const GangContract = new ethers.Contract(addresses[networkID].Gang, GangABI, provider)
        const round = await gStakingContract.round()
        const initRebaseStartTime = await gStakingContract.initRebaseStartTime()
        let currentPacks = await gStakingContract.getAllPacks()
        const reabaseAmount = await gStakingContract.getRebaseAmount()
        let gangInfo = []
        let totalCashStaked = 0;
        let totalDrugStaked = 0;
        let totalFortifications = 0;
        let totalAttacks = 0;
        if (currentPacks?.length > 0) {
            currentPacks = [].concat(currentPacks).sort((a: any, b: any) => {
                if (Number(b?.score) != Number(a?.score)) {
                    return Number(b?.score) - Number(a?.score)
                }
                return Number(a?.id) - Number(b?.id)
            })
            for (let i = 0; i < currentPacks.length; i++) {
                const element = currentPacks[i];
                totalCashStaked += element?.cashAmount / Math.pow(10, 18)
                totalDrugStaked += element?.drugAmount / Math.pow(10, 18)
                totalAttacks += Number(element?.attackCount)
                totalFortifications += Number(element?.defense)
                const info = await GangContract.gangTraits(element.id)
                const metadata = await GangContract.tokenURI(element.id)
                const imgURL = JSON.parse(atob(metadata.toString().slice(29)))?.image
                gangInfo.push({ info, imgURL })
            }
        }
        const CASHContract = new ethers.Contract(addresses[networkID].CASH as string, ierc20Abi, provider)
        const CASHtotalSupply = await CASHContract.totalSupply()
        const DRUGContract = new ethers.Contract(addresses[networkID].DRUG as string, ierc20Abi, provider)
        const DRUGtotalSupply = await DRUGContract.totalSupply()
        let data = await reqGraphHistory()
        let history = data?.gangActions;
        let dashBoardData = await reqGraphDashBord()
        return {
            // marketPrice,
            currentPacks,
            gangInfo,
            history,
            totalCashStaked,
            totalDrugStaked,
            CASHtotalSupply: CASHtotalSupply / Math.pow(10, 18),
            DRUGtotalSupply: DRUGtotalSupply / Math.pow(10, 18),
            totalFortifications,
            totalAttacks,
            round: Number(round),
            reabaseAmount,
            initRebaseStartTime: Number(initRebaseStartTime),
            dashBoardData: dashBoardData?.stakingAmount
        }
    },
);


const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        fetchAppSuccess(state, action) {
            setAll(state, action.payload);
        },
    },
    extraReducers: builder => {
        builder
            .addCase(loadAppDetails.pending, state => {
                state.loading = true;
            })
            .addCase(loadAppDetails.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(loadAppDetails.rejected, (state, { error }) => {
                state.loading = false;
            })
    },
});

const baseInfo = (state: RootState) => state.app;

export default appSlice.reducer;

export const { fetchAppSuccess } = appSlice.actions;

export const getAppState = createSelector(baseInfo, app => app);
