

interface IAddresses {
  [key: number]: { [key: string]: string };
}
/*
Gang:  0x2b4b968f07000276c80a854B9C62b33Be12B71F8
gangStaking:  0x0142c2411c509BBfAeAF8C8DCe8D5D20B67871bD
nftStaking:  0xc4C9A3153E26d573a1D19D30B479E4CC368C6Df0
drugStaking:  0x98609051171E7D194A6656b83CBB334B22c58F88
cashStaking:  0x12F7BAA00Ed8A86DD6b718e6b7098d15cE602312
stakingHelper:  0xaf36c746f40Bb50DAaD340b58964d12126bfC93c
*/
export const addresses: IAddresses = {
  250: {
    USDC: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    CASH: '0xbb4f92034927d4cE195b7D8f0acD4b3f661c5c45',

    DRUG: '0x87E3d326A72946624d3B95d96cBC132D78E4acee',
    richCity: '0x1464c6715f3E460c1C4560bB0C12fA5b35ff26Bc',
    Gang: '0x2b4b968f07000276c80a854B9C62b33Be12B71F8',
    GangStaking: '0x0142c2411c509BBfAeAF8C8DCe8D5D20B67871bD',
    StakingHelper: '0xaf36c746f40Bb50DAaD340b58964d12126bfC93c',
    nftStaking: '0xc4C9A3153E26d573a1D19D30B479E4CC368C6Df0',
    drugStaking: '0x98609051171E7D194A6656b83CBB334B22c58F88',
    cashStaking: '0x12F7BAA00Ed8A86DD6b718e6b7098d15cE602312',
    feeAddress: "0xB4d6Bf0ba18f0C785c50fcF87497eC2a82Bc0125",// fee 30%

    // RICH: '0x5A272C478609F448C566c7155577f6432fdd8B79',
    // bank: '0x67596B4c73C946287CC04b0716cb90B51b60DDEB',
    // richCity: '0xF14CFA4aDa3e328bfb0eE7823B3DD419779C4511',
    // RICH_USDC: '0x937813380c7b98a66Afa5992bf2231E3E5913eF4',
    // Staking: '0xe283f0C81c847eb9A7F8a5D832a5D9064776953D',
  },
  43113: {
    USDC: '0x4Be3561b329754693AA6fF35E140f7dC78169008',
    CASH: '0x09A3f7872D175A2A07c0b5Db86776ae95E59F5B5',
    DRUG: '0x62998265f4b3A6B17b387b5B71Ddd2673f1A0339',
    richCity: '0xE926CdC6B1245988dA4c62A571CA28A0967379F2',
    Gang: '0xa023BEe71fa354e7fAf6926a735EB819F968eA84',
    GangStaking: '0xe7C5BdCD2A9cF4871aB085fACDf9e5dA09AE0Cd0',
    StakingHelper: '0x335899fcc212F73Ab30B9F0488EcB84f050B60b4',
    nftStaking: '0x488AA5C1D7ca2c562dB36255059543Cb36a5D7da',
    drugStaking: '0x877832c4f6cc7b9083CdbD3417C81931C0E09E61',
    cashStaking: '0x17515F318600c87D92e4927848c6597bf9Df622C',
    feeAddress: "0x2a2626530d65e60Cfd199A2292342FCB06f7bB5e"
  },
};



