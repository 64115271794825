import { Box, Fade, Link, Paper, Popper, SvgIcon } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { NavLink } from "react-router-dom";
import TweenOne from 'rc-tween-one';

import './header.scss';
import Logo from '../../assets/images//gw/logo.svg'

export default function Header() {

    const isSmallScreen = useMediaQuery("(max-width: 1020px)");

    return (
        <Box display='flex' justifyContent='center' className='header-view2'>
            <div className='container'>
                <Box display='flex' alignItems='center' justifyContent="space-between" className="header">
                    <TweenOne
                        animation={{ x: -60, type: 'from', ease: 'easeOutQuad' }}>
                        <img src={Logo} style={{ height: isSmallScreen ? '30px' : 'auto' }} />
                    </TweenOne>
                    {
                        isSmallScreen ?
                            <Box display='flex' alignItems='center' justifyContent="center" className="playBg">
                                {/* <a href="https://gl1.richcity.app/" target='_blank' className="color1 font-20">Play Now</a> */}
                                <Link component={NavLink} to="/transition" className="color1 font-20">Play Now</Link>
                            </Box>
                            :
                            <TweenOne
                                animation={{ x: -60, type: 'to', ease: 'easeOutQuad' }}>
                                <Box display='flex' alignItems='center'>
                                    <Box display='flex' alignItems='center' className="navList font-16 font-Montserrat-VariableFont font-weight-b">
                                        <div className="navItem"><a href="#play" className="color1">How To Play</a></div>
                                        <div className="navItem"><a href="#tokenomics" className="color1">Tokenomics</a></div>
                                        <div className="navItem"><a href="#roadmap" className="color1">Roadmap</a></div>
                                        <div className="navItem"><a href="https://docs.gl.richcity.app/" target='_blank' className="color1">Whitepaper</a></div>
                                    </Box>
                                    {/* <a href="https://gl1.richcity.app/" target='_blank' className="color1 font-20">
                                        <Box display='flex' alignItems='center' justifyContent="center" className="playBg">Play Now</Box>
                                    </a> */}
                                    <Link component={NavLink} to="/transition" className="color1 font-20">
                                        <Box display='flex' alignItems='center' justifyContent="center" className="playBg">Play Now</Box>
                                    </Link>
                                </Box>
                            </TweenOne>
                    }
                </Box>
            </div>
        </Box>
    )
}