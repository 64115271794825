import apollo from "src/lib/apolloClient";

//nft 数据
export const reqGraphNFT = async (address) => {
    const protocolMetricsQuery = `
    query { 
        _meta {
            block {
                number
            }
        }
        userNFT(first:1000,id: "${address.toLocaleLowerCase()}") {
            nftData(first:1000,orderBy:id) {
                id
                staking1
                staking0
                metaData
            }
        }
      }
  `;

    try {
        const graphData = await apollo(protocolMetricsQuery);
        return graphData?.data  //JSON.parse(atob(base64_encoded_JSON.toString().slice(29)))
    } catch (error) {
        return null
    }
}

export const reqGraphHistory = async () => {
    const protocolMetricsQuery = `
    query { 
        _meta {
            block {
                number
            }
        }
        gangActions(first:1000,orderBy: timestamp, orderDirection:desc) {
            id
            gangId
            targetId
            isAttack
            gangName
            targetName
            count
            timestamp
        }
      }
  `;

    try {
        const graphData = await apollo(protocolMetricsQuery);
        return graphData?.data  //JSON.parse(atob(base64_encoded_JSON.toString().slice(29)))
    } catch (error) {
        return null
    }
}

export const reqGraphDashBord = async () => {
    const protocolMetricsQuery = `
    query { 
        _meta {
            block {
                number
            }
        }
        stakingAmount(id:"1") {
            id
            gangsterStaked
            leadStaked
            swatStaked
            evaStaked
        }
      }
  `;

    try {
        const graphData = await apollo(protocolMetricsQuery);
        return graphData?.data  //JSON.parse(atob(base64_encoded_JSON.toString().slice(29)))
    } catch (error) {
        console.log("graph error=======>", error)
        return null
    }
}

export const reqMintLeaderboard = async () => {
    const protocolMetricsQuery = `
    query { 
        userNFTs(first:10,orderBy: mintCount, orderDirection:desc) {
            id
            mintCount
        }
      }
  `;

    try {
        const graphData = await apollo(protocolMetricsQuery);
        return graphData?.data  //JSON.parse(atob(base64_encoded_JSON.toString().slice(29)))
    } catch (error) {
        console.log("graph error=======>", error)
        return null
    }
}